.h2 {
  color: var(--color-grey-dark);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.h3 {
  color: var(--color-primary);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14;
}

.h4 {
  font-weight: 400;
  font-size: 26px;
  color: var(--color-grey-dark);
  margin-bottom: 0;
}

.h5 {
  color: #c3d9eb;
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
