.postsDiv {
  display: flex;
  flex-wrap: wrap;
  width: 71vw;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
}

.card1,
.card2,
.card3,
.card4,
.card5,
.card6 {
  padding: 1rem;
  position: relative;
  margin: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.card1,
.card4 {
  width: 290px;
  height: 219px;
}

.card2,
.card5 {
  width: 290px;
  height: 177px;
}

.card3,
.card6 {
  width: 290px;
  height: 286px;
}

.card4 {
  height: 320px;
}
.card5 {
  height: 321px;
}
.card6 {
  height: 229px;
}

.img {
  position: absolute;
  left: 20px;
  top: 25px;
}

.publish {
  left: 200px;
  top: 269px;
}

h3 {
  position: absolute;
  left: 73px;
  top: 20px;
  color: #232324;
}

.delete {
  top: 22px;
  right: 20px;
  position: absolute;
}

.text,
.jacktext {
  text-align: 'left';
  width: 220px;
  position: absolute;
  left: 43px;
  top: 50.4px;
}

.approve,
.unapproved,
.approved,
.card6Approve {
  position: absolute;
  color: #7cd2c6;
  left: 43px;
  bottom: 15px;
}

.approved {
  top: 140px;
}

.unapproved {
  color: #eb7a7a;
}

.reply {
  color: #313135;
  position: absolute;
  right: 38px;
  bottom: 15px;
}

.manAvatar {
  position: absolute;
  left: 38px;
  top: 185px;
}

.jack {
  position: absolute;
  left: 89px;
  bottom: 73px;
  font-weight: 600;
  color: #232324;
}

.jacktext {
  left: 50px;
  top: 220px;
}

.card4Approve,
.card4Reply,
.card5Reply,
.card6Reply {
  position: absolute;
  top: 278px;
  right: 110px;
  padding: 0.3rem;
  border: 1px solid #e4e9f2;
  border-radius: 4px;
  opacity: 1;
}

.card6Approve,
.card6Reply {
  top: 175px;
  right: 180px;
}

.card6Reply {
  right: 20px;
}

.card4Reply {
  right: 38px;
}

.card5Reply {
  top: 140px;
  right: 38px;
}

.input {
  position: absolute;
  text-align: left;
  color: #303135;
  opacity: 1;
  left: 58px;
  bottom: 79px;
  border: 1px solid #7cd2c6;
  border-radius: 4px;
  padding: 0.2rem 4rem;
}
.cardDiv {
  /* background-image: url('../../Assets/Images/whiteBg.png');
  background-position: center;
  background-size: cover; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 20px #dcdde266;
  border-radius: 12px;
  opacity: 1;
  margin: 0 auto;
  /* max-width: 540px;
  min-height: 380px;
  max-height: 380px; */
}
.imageContainer {
  height: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
