.activeBtnStyle {
  background-color: #7cd2c6;
  border-radius: 40px;
  width: 137px;
  height: 48px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
}

.activeBtnStyle:active,
.activeBtnStyle:hover,
.activeBtnStyle:focus {
  border-color: #7cd2c6 !important;
  background: #7cd2c6 !important;
  color: white !important;
}

.btnSecondary {
  display: flex;
  align-items: center;
  border: 1px solid #d8dce6;
  background-color: transparent;
  border-radius: 26px;
  padding: 0px 10px;
  outline: none;
}

.btnSecondary p {
  padding: 0 5px;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.btnSecondary img {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}

.logout {
  padding: 0 2rem;
  border: 1px solid #d8dce6 !important;
}

.dropdown {
  border: 1px solid transparent;
  margin: 0px 0 0 -2rem;
}

@media screen and (max-width: 600px) and (min-width: 200px) {
  .btnMain {
    display: none;
  }
  .uploadBtn {
    color: white;
    background-color: #7cd2c6 !important;
  }
}

@media screen and (min-width: 600px) {
  .btnMain {
    background-color: var(--color-primary);
    border: 2px solid transparent;
    font-weight: 300;
    color: #fff;
    font-size: 14px;
    padding: 0px 10px;
    border-radius: 24px;
    transition: 0.2s ease-in-out;
    outline: none;
    cursor: pointer;
  }
  .uploadBtn {
    display: none;
  }
}

.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #303135;
}

.heading2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #303135;
}

.subscription_card {
  max-width: 500px;
  box-shadow: 0px 0px 30px rgba(48, 49, 53, 0.16);
  border-radius: 10px;
  border: 4px solid #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 8px;
  padding: 22px;
}

.subscription_card_content {
  max-width: 550px;
  max-height: 500px;
  height: 420px;
  width: 550px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 22px;
  margin-top: 30px;
}

ul {
  list-style: none;
  /* Remove default bullets */
}

.subscription_points li::before {
  content: '\2022';
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #7cd2c6;
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 8px;
  /* Also needed for space (tweak if needed) */
  /* margin-left: -1em; Also needed for space (tweak if needed) */
}

.second_block {
  width: 144px;
  text-align: end;
}
