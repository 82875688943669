.fullPageLoaderContainer {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loader {
  /* position: absolute;
  left: 44vw;
  top: 42vh; */
  z-index: 100;
}
