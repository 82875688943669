.UploadDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #0000000a;
  border-radius: 4px;
  opacity: 1;
  width: 255px;
  height: 277px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.UploadFanpageDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #0000000a;
  border-radius: 4px;
  opacity: 1;
  width: 255px;
  /* height: 210px; */
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.uploadSingleDiv {
  background: #f2fbf9 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 75px;
  width: 101px;
}
.UploadLabel {
  margin-top: 25px;
  width: 140px;
  /* height: 24px; */
  font-size: 18px;
  margin-bottom: 10px;
}
.UploadLabel1 {
  font-size: 12px;
  color: #7cd2c6;
  opacity: 1;
}
.selectedPageCount {
  background: #7cd2c6 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  width: 40px;
  height: 40px;
  z-index: 2;
  color: white;
  margin: 10px;
  padding: 8px;
  font-weight: bold;
  font-size: 15px;
}
.UnselectedPageCount {
  background: #f2fbf9 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  width: 40px;
  z-index: 2;
  color: #7cd2c6;
  margin: 10px;
  padding: 8px;
  height: 40px;
}
.UploadedlistDiv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 20px #dcdde266;
  border-radius: 12px;
  opacity: 1;
  height: 67px;
  padding-top: 16px;
  margin-top: 20px;
}
.reactCalender {
  border: 1px solid white !important;
  margin: 0 auto;
  width: 280px !important;
}

.Imgcontent {
  width: 220px;
  /* height: 220px; */
  /* margin-left: 9vw; */
  /* padding-top: 10px; */
  border-radius: 12px;
}

.contentDiv {
  display: flex;
}

.label {
  float: left;
  padding-top: 20px;
  color: #232324;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  /* margin-left: 30px; */
}

.albumsDiv {
  /* margin-top: 20px; */
  display: flex;
  flex-wrap: wrap;
  /* padding-left: 6px; */
}
/* img {
  cursor: pointer;
} */

.button {
  text-align: center;
  font-size: 14px;
  letter-spacing: 1.12px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  background: #7cd2c6 0% 0% no-repeat padding-box;
  font-weight: bold;
  font-size: 15px;
  border-radius: 24px;
}
.button:active,
.button:hover,
.button:focus {
  border-color: transparent !important;
  background: #7cd2c6 !important;
  color: #ffffff !important;
}
.buttonWithIcon {
  display: 'flex';
}

.container {
  position: relative;
}

.undo,
.redo,
.bgColor,
.fontFamily,
.image,
.text {
  position: absolute;
  top: 16px;
  background-color: white;
  color: #7cd2c6;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  z-index: 100;
}
.undo {
  left: 16px;
}
.redo {
  left: 67px;
}

.bgColor {
  left: 118px;
  color: black;
}

.fontFamily {
  right: 175px;
  color: black;
  /* padding-right: 12px; */
}
.image {
  right: 74px;
  color: black;
  z-index: 100;
}
.text {
  right: 16px;
}

.pt1,
.pt2,
.deleteText {
  position: absolute;
  top: 80px;
  right: 16px;
  background-color: white;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.deleteText {
  color: #7cd2c6;
  right: 218px;
  font-size: 20px;
  padding: 5px;
  width: 40px;
}

.pt1 {
  right: 110px;
}

.changeText {
  position: absolute;
  left: 16px;
  bottom: 16px;
  background-color: white;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  cursor: pointer;
}

.changeText p {
  margin: 0px;
}

.removeImage {
  position: absolute;
  right: 16px;
  bottom: 16px;
  background-color: white;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  cursor: pointer;
}

.removeImage p {
  margin: 0px;
}

.newClass {
  height: calc(100% - 110px);
  /* border-bottom : 1px solid #707070; */
}

.greenColorBtn {
  display: block;
  border-color: #7cd2c6;
  background: #7cd2c6 0% 0% no-repeat padding-box;
  font-weight: bold;
  font-size: 15px;
  border-radius: 24px;
  width: 315px;
  height: 48px;
  text-transform: uppercase;
  color: white;
}
.greenColorBtn:active,
.greenColorBtn:hover,
.greenColorBtn:focus {
  border-color: #7cd2c6 !important;
  background: #7cd2c6 !important;
  color: white !important;
}
.whiteColorBtn {
  display: block;
  border-color: #7cd2c6;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 315px;
  height: 48px;
  font-weight: bold;
  font-size: 15px;
  color: #7cd2c6;
}
.whiteColorBtn:active,
.whiteColorBtn:hover,
.whiteColorBtn:focus {
  border-color: #7cd2c6 !important;
  background: #ffffff !important;
  color: #7cd2c6 !important;
}
.songPrice {
  height: 28px;
  background-color: #7cd2c6;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 3px;
  position: absolute;
  right: 15px;
  margin-top: -28px;
  font-weight: 600;
}
.notPushed {
  height: 28px;
  background-color: #fadcb1;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 3px;
  position: absolute;
  right: 15px;
  margin-top: -28px;
  font-weight: 600;
}
.coverImageStyle {
  height: 160px;
  margin: 8px 11px 0 0;
  border: 1px dashed #7cd2c6;
  border-radius: 12px;
}
.coverImageSize {
  border-radius: 4px;
}

@media screen and (max-width: 1200px) {
  .contentDiv {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) and (min-width: 200px) {
  .cardDiv {
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 12px;
    opacity: 1;
    min-height: 800px;
    margin: -26px 0 0 0px;
  }
  .leftArrowIcon {
    margin-right: 12px;
  }
  .rightArrowIcon {
    margin-left: 12px;
  }
  .label {
    font-size: 14px;
  }
  .UploadDiv {
    height: 180px;
    width: 160px;
  }
  .uploadSingleDiv {
    width: 60px;
    height: 60px;
  }
  .audioIcon {
    width: 50%;
  }
  .UploadLabel {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .UploadLabel1 {
    font-size: 12px;
  }
  .coverImageSize {
    width: 160px;
    height: 160px;
  }
  .songPrice {
    top: 135px;
  }
  .titleStyle {
    text-align: left;
    font-size: 14px;
    width: 160px;
  }
  .fontFamily {
    top: 140px;
    right: 16px;
    color: black;
  }
  .image {
    top: 142px;
    right: 130px;
    color: black;
    z-index: 100;
  }
}
.cardDiv2 {
  margin: 0 auto 0 0;
}

@media screen and (max-width: 1440px) and (min-width: 600px) {
  .cardDiv2 {
    margin: 0 0 0 auto;
  }
}

@media screen and (min-width: 600px) {
  .cardDiv {
    background-repeat: no-repeat;
    border-radius: 12px;
    opacity: 0.9;
    margin: -26px 0 0 auto;
  }
  .leftArrowIcon {
    margin-right: 86px;
  }
  .rightArrowIcon {
    margin-left: 86px;
  }
  .coverImageSize {
    width: 255px;
  }
  .titleStyle {
    text-align: left;
    font-size: 18px;
    width: 255px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
