.btnContainer {
  display: flex;
}

.btnTop {
  margin-left: 1vw;
}
@media screen and (max-width: 600px) and (min-width: 200px) {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px var(--color-grey-light);
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  .rightBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    /* padding: 0 2rem; */
  }
  .imageContainer {
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    /* padding-left: 2rem; */
  }
  .imgContainer {
    display: none;
  }
  .imgContainer1 {
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .rightContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 2rem; */
  }
}

@media screen and (min-width: 600px) {
  .container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    box-shadow: 0px 1px 0px var(--color-grey-light);
    display: flex;
    align-items: center;
  }
  .rightBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    padding: 0 2rem;
  }
  .imageContainer {
    width: 270px;
    min-width: 270px;
    text-align: left;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 2rem;
  }
  .imgContainer {
    width: 270px;
    min-width: 270px;
    text-align: left;
    padding-left: 2rem;
    border-right: 1px solid var(--color-grey-light);
    height: 100%;
    display: flex;
    align-items: center;
  }
  .imgContainer1 {
    display: none;
  }
  .rightContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
  }
}
