@media screen and (max-width: 600px) and (min-width: 200px) {
  .container {
    height: calc(100vh - 90px);
    z-index: 100;
    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  }
}
@media screen and (min-width: 600px) {
  .container {
    width: 270px;
    min-width: 270px;
    position: fixed;
    left: 0;
    top: 70px;
    border-right: 1px solid #eaedf3;
    height: calc(100vh - 70px);
    z-index: 100;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    overflow: auto;
  }
}
.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  font-size: 14px;
  color: #7cd2c6;
  padding: 15px 12px;
  width: 100%;
  border-radius: 25px;
  transition: 0.2s all ease-in-out;
  background-color: #ededed;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 52px;
}

.active {
  background-color: var(--color-primary) !important;
  padding-left: 15px;
  color: #fff !important;
}
.shaded {
  color: #7cd2c6;
  background-color: transparent;
}

.iconDetails {
  height: 22px;
  width: 22px;
}
