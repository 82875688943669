:root {
  --color-primary: #7cd2c6;
  --color-grey-light: #eaedf3;
  --color-grey-dark: #232324;
}

.App {
  text-align: center;
  background-color: white;
  min-width: 1024px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 12px 16px !important;
}

.code-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
}

.code-label {
  margin-bottom: 16px;
  align-self: flex-start;
  font-family: 'Barlow', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #303135;
}

.code-inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 60px;
  align-self: flex-start;
}

.code-inputs input {
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 45px;
  width: 45px;
  border-radius: 4px;
  /* margin: 0 16px; */
  border: 2px solid #4f5b66;
  font-size: 24px;
}

.code-inputs input:focus {
  outline: none;
}

.ant-switch-checked {
  background-color: #7cd2c6 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.errMsg {
  font-size: 12px !important;
  color: red;
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Barlow', sans-serif !important;
  background-color: white;
}

.aContent {
  background-color: #f2fbf9;
  min-height: 100vh;
  /* padding-top: 110px; */
  overflow: auto;
  overflow-x: hidden;
}

.ant-table-thead > tr > th {
  background-color: #fff !important;
  color: var(--color-primary) !important;
}

.ant-table-pagination.ant-pagination {
  padding: 16px;
}

.ant-btn-group .ant-btn {
  border: 1px solid white !important;
}

.react-calendar__tile--active {
  background: #7cd2c6 0% 0% no-repeat padding-box !important;
  color: white;
  border-radius: 30px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #7cd2c6 !important;
  border-radius: 30px;
  opacity: 1;
  width: 16vw;
  margin: 2vw;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: var(--color-primary) !important;
}

/*media queries for Fan Page*/

@media only screen and (min-width: 1440px) {
  .fan-page-label {
    margin-left: -1vw;
  }
}

@media only screen and (max-width: 1440px) {
  .fan-page-label {
    margin-left: -2vw;
  }
}

.ant-upload-list {
  display: none !important;
}

/* media queries for Fan Page Post Creation*/

.tui-image-editor-header-logo {
  display: none !important;
}

.ant-picker-time-panel-column::after {
  height: 0px !important;
}

.ant-input {
  border-radius: 6px !important;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: #7cd2c6 !important;
  box-shadow: none !important;
}

.ant-input-password:hover,
.ant-input-password:focus,
.ant-input-focused {
  border-color: #7cd2c6 !important;
  box-shadow: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-right: 0px !important;
  content: '' !important;
}

.ant-form-item-explain.ant-form-item-explain-error {
  display: flex !important;
  width: 316px !important;
}

.ant-form-item-label > label {
  color: #303135 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7cd2c6 !important;
  border-color: #7cd2c6 !important;
}

.ant-btn-primary {
  background-color: #7cd2c6 !important;
  border-color: #7cd2c6 !important;
}

@media screen and (min-width: 600px) {
  .uploadSongWithoutcover {
    position: relative !important;
    margin-left: 16px;
    top: -8px;
  }
  .content {
    width: calc(100% - 270px);
    margin-left: auto;
    padding: 30px;
    background-color: #f2fbf9;
    min-height: 100vh;
    padding-top: 100px;
    overflow: auto;
  }
  .dashboardSidebar {
    display: block;
  }
  .mobileSideBar {
    display: none;
  }
}

@media screen and (max-width: 600px) and (min-width: 200px) {
  .uploadSongWithoutcover {
    position: relative !important;
    margin-left: 8px;
    top: -4px;
  }
  .content {
    padding: 16px;
    background-color: #f2fbf9;
    min-height: 100vh;
    padding-top: 100px;
    overflow: auto;
  }
  .dashboardSidebar {
    display: none;
  }
  .mobileSideBar {
    display: block;
  }
}
