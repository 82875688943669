.container {
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 3px #0000000a;
  border-radius: 4px;
}

.box {
  padding: 1.5rem;
  background-color: #fff;
  text-align: left;
  flex: 1;
}

.headingBox {
  border-bottom: 1px solid var(--color-grey-light);
  background-color: #fff;
  padding: 0.5rem 1rem;
  text-align: left;
}

.heading {
  font-weight: 400;
  font-size: 20px;
  color: #3e3e3e;
  margin-bottom: 0;
}

.innerBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loadingContainer {
  height: 100%;
  width: 100%;
}

.dataConatiner {
  text-align: right;
  width: 70%;
}

.textContainer {
  padding-right: 1vw;
}
.textContainer:first-child {
  border-bottom: 1px solid var(--color-grey-light);
}

.textContainer p {
  color: var(--color-primary);
  font-weight: 500;
}

.bottomRow {
  margin-top: 30px;
}
