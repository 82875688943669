.SignUpButton {
  background: #7cd2c6 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 15;
  border-color: #7cd2c6;
  width: 295px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  height: 48px;
}
.SignUpButton:active,
.SignUpButton:hover,
.SignUpButton:focus {
  border-color: #7cd2c6 !important;
  background: #7cd2c6 !important;
  color: white !important;
}
.LoginButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 15;
  width: 295px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  height: 48px;
}
.LoginButton:active,
.LoginButton:hover,
.LoginButton:focus {
  border-color: transparent !important;
  background: #ffffff !important;
  color: #000000 !important;
}
.mainDiv {
  background: #f2fbf9 0% 0% no-repeat padding-box;
  min-height: 100vh;
}
.signInLogo {
  margin-top: 60px;
  width: 614px;
  height: 600px;
}

.greenColorBtn {
  display: block;
  border-color: #7cd2c6;
  background: #7cd2c6 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 315px;
  height: 48px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}
.greenColorBtn:active,
.greenColorBtn:hover,
.greenColorBtn:focus {
  border-color: #7cd2c6 !important;
  background: #7cd2c6 !important;
  color: white !important;
}
.whiteColorBtn {
  display: block;
  border-color: #7cd2c6;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 315px;
  height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  color: #7cd2c6;
}
.whiteColorBtn:active,
.whiteColorBtn:hover,
.whiteColorBtn:focus {
  border-color: #7cd2c6 !important;
  background: #ffffff !important;
  color: #7cd2c6 !important;
}
.disableColorBtn {
  display: block;
  background: #e4e9f2 0% 0% no-repeat padding-box !important;
  border-radius: 24px;
  width: 315px;
  font-weight: bold;
  font-size: 15px;
  height: 48px;
  border-color: transparent !important;
  text-transform: uppercase;
  color: #ffffff !important;
}
.disableColorBtn:active,
.disableColorBtn:hover,
.disableColorBtn:focus {
  border-color: transparent !important;
  background: #e4e9f2 !important;
  color: #ffffff !important;
}
.formDiv {
  padding-top: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 600px) and (min-width: 200px) {
  .homePage {
    border-radius: 30px;
    height: calc(100% - 32px - 70px);
    width: calc(100vw - 32px);
    background-repeat: no-repeat;
    background-image: url('../../Assets/Images/logo.png');
    background-position-y: top;
    background-position-x: center;
    background-origin: content-box;
    display: block;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
  .btnPosition {
    position: absolute;
    top: calc(min(100% - 100px, 700px));
    left: 0;
    right: 0;
  }
  .signInDiv {
    background-repeat: no-repeat;
    border-radius: 12px;
    opacity: 1;
    background-position: center;
    overflow: hidden;
  }
  .storeContainer {
    display: flex;
    flex-direction: column;
  }
  .appStoreMenuItem {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .slideDiv {
    border-radius: 12px;
    opacity: 1;
    margin: -16px auto;
    background-position: center;
    min-height: 800px;
  }
  .SignUpButton,
  .LoginButton,
  .greenColorBtn,
  .whiteColorBtn,
  .disableColorBtn {
    width: 220px;
  }
  .formDiv {
    /*padding-left: 0px;*/
  }
}
@media screen and (min-width: 600px) {
  .homePage {
    background-repeat: no-repeat;
    background-image: url('../../Assets/Images/logo.png');
    background-position-y: top;
    background-position-x: center;
    height: calc(100% - 16px);
    min-height: 840px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .btnPosition {
    position: absolute;
    top: 650px;
    left: 0;
    right: 0;
  }
  .signInDiv {
    background-repeat: no-repeat;
    border-radius: 12px;
    background-position: center;
    opacity: 1;
    margin: 0 auto;
    max-width: 700px;
    height: calc(100% - 16px);
    overflow: hidden;
  }
}

@media screen and (min-width: 1000px) {
  .cropModal {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1000px) {
  .cropModal {
    display: flex;
    flex-wrap: wrap;
  }
}
