.react-pdf__Page__annotations.annotationLayer {
  margin-bottom: 20px;
  height: 0px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}
